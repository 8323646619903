<template>
  <div>
    <UIExit label="EXIT PERSONAL SETTINGS" />
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import layoutConstants from '@src/constants/layout-constant.js'
import UIExit from '@src/components/UiElements/UIExit.vue'

export default {
  page: {
    title: 'Settings | User',
    meta: [
      {
        name: 'description',
        content: 'MyQampus User related Settings',
      },
    ],
  },
  components: {
    UIExit,
  },
  created() {
    this.setLeftbarContent({
      back: '',
      customeHeader: 'left-bar-class-list',
      SHOW_SECTION_DROPDOWN_TO: layoutConstants.showSectionDropdownTo,
      SHOW_CLASS_DROPDOWN_TO: layoutConstants.showClassDropdownTo,
      routes: [
        {
          id: '001',
          name: 'Profile Settings',
          role: ['super_admin', 'section_teacher', 'section_student', 'staff'],
          showChilds: false,
          childs: [
            {
              name: 'perosnal-info',
              route: { name: 'perosnal-info' },
            },
            {
              name: 'contact-info',
              route: { name: 'contact-info' },
            },
            {
              name: 'professional-info',
              route: { name: 'professional-info' },
            },
            {
              name: 'salary-info',
              route: { name: 'salary-info' },
            },
          ],
        },

        {
          id: '002',
          name: 'Security Settings',
          role: ['super_admin', 'section_teacher', 'section_student', 'staff'],
          showChilds: false,
          childs: [
            {
              name: 'change-password',
              route: { name: 'change-password' },
            },
            {
              name: 'change-email',
              route: { name: 'change-email' },
            },
            {
              name: 'change-phone',
              route: { name: 'change-phone' },
            },
          ],
        },

        {
          id: '004',
          name: 'Theme Settings',
          role: ['super_admin', 'section_teacher', 'section_student', 'staff'],
          route: { name: 'theme-settings' },
        },
      ],
    })
  },
  methods: {
    ...mapActions('layout', ['setLeftbarContent', 'modifyLeftBarDropdown']),
  },
}
</script>
